import React from 'react';

import { useSuperHubStore } from '@core/store';

import { CollapsedStateProvider } from '../CollapsedState';
import { ConfirmDeletionProvider } from '../ConfirmDeletion';

import { SidebarNavContextProvider } from './Context';
import { SidebarNavErrorStatus } from './ErrorStatus';
import { SidebarNavList } from './List';
import { SidebarNavRoot } from './Root';

export interface SidebarNavTreeProps {
  className?: string;
}

export const SidebarNavTree = React.memo(function SidebarNavTree({ className }: SidebarNavTreeProps) {
  const [categories, totalUnrenderable] = useSuperHubStore(s => [s.sidebar.data, s.sidebar.totalUnrenderable]);

  return (
    <SidebarNavContextProvider>
      <SidebarNavRoot className={className}>
        <SidebarNavErrorStatus totalUnrenderable={totalUnrenderable} />
        <ConfirmDeletionProvider>
          <CollapsedStateProvider>
            <SidebarNavList data={categories} />
          </CollapsedStateProvider>
        </ConfirmDeletionProvider>
      </SidebarNavRoot>
    </SidebarNavContextProvider>
  );
});
