import React from 'react';

import { useSuperHubStore } from '@core/store';

import { PageNavItem } from '@ui/Dash/PageNav';

export function SidebarNavDefinitions() {
  const isReferenceDefinitionsRoute = useSuperHubStore(s => s.isReferenceDefinitionsRoute);

  return (
    <PageNavItem
      active={isReferenceDefinitionsRoute}
      label="API Definitions"
      showActions={false}
      to="/update/reference"
      type="apiDefinitions"
    />
  );
}
