import type { TreeNode } from './types';
import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { ListChildComponentProps } from 'react-window';

import React, { useEffect, useMemo, useRef } from 'react';
import { useDndScrolling } from 'react-dnd-scrolling';
import { useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, VariableSizeList } from 'react-window';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { PageNavDivider } from '@ui/Dash/PageNav';

import { SidebarNavCategory } from './Category';
import { SidebarNavDefinitions } from './Definitions';
import styles from './List.module.scss';
import { SidebarNavNewCategory } from './NewCategory';
import { SidebarNavNewPage } from './NewPage';
import { SidebarNavPage } from './Page';
import useTreeNodes, { defaultPageItemHeight } from './useTreeNodes';

/**
 * Renders each virtual list item given the provided node index. We look up the
 * `TreeNode` at this particular slot to determine whether we need to render a
 * category, page, divider, etc.
 */
const Item = React.memo(function Item({ data: treeNodes, index, style }: ListChildComponentProps<TreeNode[]>) {
  const bem = useClassy(styles, 'SidebarNavListItem');
  const node = treeNodes[index];

  return (
    <div className={bem()} style={{ ...style }}>
      {node.type === 'category' ? (
        <SidebarNavCategory node={node} />
      ) : node.type === 'page' ? (
        <SidebarNavPage node={node} />
      ) : node.type === 'divider' ? (
        <PageNavDivider collapsible={false} includeLine={node.includeLine} />
      ) : node.type === 'api_definitions' ? (
        <SidebarNavDefinitions />
      ) : node.type === 'new_category' ? (
        <SidebarNavNewCategory node={node} />
      ) : node.type === 'new_page' ? (
        <SidebarNavNewPage node={node} />
      ) : null}
    </div>
  );
}, areEqual);

interface SidebarNavListProps {
  data: GitSidebarCategory[];
}

/**
 * Renders the provided nested data structure with a virtualized list that is
 * capable of handling very large data sets.
 */
export function SidebarNavList({ data }: SidebarNavListProps) {
  const bem = useClassy(styles, 'SidebarNavList');
  const { slug: routeSlug } = useParams<SuperHubRouteParams>();
  const [routeSection] = useSuperHubStore(s => [s.routeSection]);

  /**
   * Ref for the virtual list instance so we can access its API methods.
   * @link https://react-window.vercel.app/#/api/VariableSizeList
   */
  const listRef = useRef<VariableSizeList>(null);

  const {
    nodes: treeNodes,
    scrollTarget,
    selectedNode,
  } = useTreeNodes({
    data,
    isChangelog: routeSection === 'changelog',
    isReference: routeSection === 'reference',
    selectedPageSlug: routeSlug,
  });

  // Whenever our tree changes when navigating between sections, we need to
  // reset the list to avoid rendering the new list with incorrect dimensions.
  useEffect(() => {
    if (treeNodes) {
      listRef.current?.resetAfterIndex(0, true);
    }
  }, [treeNodes]);

  // Run only on the initial mount b/c we only want to scroll to the selected
  // page once and never again.
  useEffect(() => {
    if (selectedNode) {
      window.requestAnimationFrame(() => {
        listRef.current?.scrollToItem(selectedNode.index, 'center');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When a scroll target is set, scroll the virtual list to make it visible.
  // Wait for next animation frame to ensure list is rendered before executing.
  useEffect(() => {
    if (scrollTarget) {
      window.requestAnimationFrame(() => {
        listRef.current?.scrollToItem(scrollTarget.index, 'auto');
      });
    }
  }, [scrollTarget]);

  /**
   * Ref for the outer container of the virtual list that lets us scroll the
   * list while dragging an item up or down.
   */
  const listContainerRef = useRef<VariableSizeList>(null);
  useDndScrolling(listContainerRef, {
    strengthMultiplier: 50,
  });

  /** Calculates the total tree list height to be used during SSR. */
  const defaultListHeight = useMemo(() => {
    return treeNodes.reduce((height, node) => {
      return height + node.height;
    }, 0);
  }, [treeNodes]);

  return (
    <AutoSizer defaultHeight={defaultListHeight} defaultWidth={280}>
      {autoSizerProps => (
        <VariableSizeList
          ref={listRef}
          className={bem()}
          estimatedItemSize={defaultPageItemHeight}
          itemCount={treeNodes.length}
          itemData={treeNodes}
          itemKey={(index, nodes) => {
            const node = nodes[index];
            return 'data' in node ? node.data.uri : node.index;
          }}
          itemSize={index => treeNodes[index].height}
          outerRef={listContainerRef}
          overscanCount={5} // Tweak this to optimize empty flash scrolling.
          {...autoSizerProps}
        >
          {Item}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
}
