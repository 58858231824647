import type {
  TreeNode,
  TreeNodeDivider,
  TreeNodeApiDefinitions,
  TreeNodeCategory,
  TreeNodePage,
  TreeNodeNewPage,
  TreeNodeNewCategory,
} from './types';
import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import { useState, useMemo } from 'react';

import { isApiConfigCategory } from '@core/store/SuperHub/Sidebar/util';

import { useCollapsedStateContext } from '../CollapsedState';

import { useSidebarNavContext } from './Context';
import useDraggingMonitor from './useDraggingMonitor';
import useEphemeralPageData from './useEphemeralPageData';

/**
 * Default height of each category node in the virtual list. This number must be
 * precise in order for drag/drop target highlights to line up correctly.
 */
export const defaultCategoryItemHeight = 28;

/**
 * Default height of each page node in the virtual list. This number must be
 * precise in order for drag/drop target highlights to line up correctly.
 */
export const defaultPageItemHeight = 29.5;

interface useTreeNodesProps {
  /** Nested sidebar data structure, including fixed categories. */
  data: GitSidebarCategory[];
  /** Indicates that we are editing the Changelog section. */
  isChangelog?: boolean;
  /** Indicates that we are editing the API Reference section. */
  isReference?: boolean;
  /**
   * Indicates that a page is selected and should be used as the `scrollTarget`
   * to ensure it is visible in the virtual list.
   */
  selectedPageSlug?: string;
}

/**
 * Reduces the nested sidebar data structure into a flattened array of tree
 * nodes that can be used to render a virtual list window that is capable of
 * rendering very large data sets efficiently. Each item in the flattened array
 * is `TreeNode` that contains information about what type of node it is in
 * addition to original sidebar data.
 * @example
 * ```tsx
 * const { nodes } = useTreeNodes({ sidebarData });
 * <VariableSizeList
 *   itemCount={nodes.length}
 *   itemData={nodes}
 *   itemSize={index => nodes[index].height}
 * >
 *   {Row}
 * </VariableSizeList>
 * ```
 */
export default function useTreeNodes({ data, isChangelog, isReference, selectedPageSlug }: useTreeNodesProps) {
  const { ephemeralCategory } = useSidebarNavContext();
  const { collapsedStateById, updateCollapsedStateById } = useCollapsedStateContext();
  const [isOpenMap, setIsOpenMap] = useState<Record<string, boolean>>(collapsedStateById || {});
  const { draggingCategory, draggingPage } = useDraggingMonitor();
  const getEphemeralPageData = useEphemeralPageData();

  const treeNodes = useMemo(() => {
    /**
     * References the tree node that should be made visible in the list view,
     * typically by scrolling the list to that item.
     */
    let scrollTarget: TreeNode | undefined;

    /** References the page node that is currently selected if one exists. */
    let selectedNode: TreeNodePage | undefined;

    /**
     * Global index counter incremented and assigned to every node in the tree.
     * Serves as the unique key to access any node by index in O(1).
     */
    let treeIndex = -1;

    /**
     * Returns an invocable function that's bounded to a node's index. When
     * invoked, the node at this slot has its "opened" state toggled.
     */
    function getToggleFn(uri: string, isOpen: boolean) {
      return () => {
        setIsOpenMap(value => ({ ...value, [uri]: !isOpen }));
        updateCollapsedStateById(uri, !isOpen);
      };
    }

    function getDivider(height: number, includeLine: boolean = false) {
      treeIndex += 1;
      const node: TreeNodeDivider = {
        height,
        includeLine,
        index: treeIndex,
        type: 'divider',
      };
      return [node];
    }

    function getApiDefinitions() {
      if (!isReference) return [];
      treeIndex += 1;
      const node: TreeNodeApiDefinitions = {
        height: defaultPageItemHeight,
        index: treeIndex,
        type: 'api_definitions',
      };
      return [node, ...getDivider(10, true)];
    }

    function getEphemeralPage(parent: TreeNodeCategory | TreeNodePage) {
      const ephemeralPage = getEphemeralPageData(parent);
      if (!ephemeralPage) return [];
      treeIndex += 1;
      const node: TreeNodeNewPage = {
        height: defaultPageItemHeight,
        index: treeIndex,
        label: ephemeralPage.label,
        nestingLevel: 'nestingLevel' in parent ? parent.nestingLevel + 1 : 0,
        pageType: ephemeralPage.type,
        type: 'new_page',
      };
      scrollTarget = node;
      return [node];
    }

    function getEphemeralCategory() {
      if (!ephemeralCategory) return [];
      treeIndex += 1;
      const node: TreeNodeNewCategory = {
        height: defaultCategoryItemHeight,
        index: treeIndex,
        label: '',
        type: 'new_category',
      };
      scrollTarget = node;
      return [node, ...getDivider(10)];
    }

    function getChildren(
      parent: TreeNodeCategory | TreeNodePage,
    ): (TreeNodeCategory | TreeNodeNewPage | TreeNodePage)[] {
      // When parent is closed, don't render any of it children.
      if (!parent.isOpen) return [];

      const children = (parent.type === 'category' ? parent.data.pages : parent.data.pages) || [];
      return children.flatMap((page, index, pages) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return getPage(page, index, pages.length, parent);
      });
    }

    function getPage(
      page: GitSidebarPage,
      position: number,
      positionLength: number,
      parent: TreeNodeCategory | TreeNodePage,
    ) {
      treeIndex += 1;
      const isOpen = isOpenMap[page.uri] ?? true;
      const node: TreeNodePage = {
        categoryTitle: 'categoryUri' in parent ? parent.categoryTitle : parent.data.title,
        categoryUri: 'categoryUri' in parent ? parent.categoryUri : parent.data.uri,
        data: page,
        height: defaultPageItemHeight,
        index: treeIndex,
        // Close the page that is being dragged.
        isOpen: draggingPage === page.uri ? false : isOpen,
        nestingLevel: 'nestingLevel' in parent ? parent.nestingLevel + 1 : 0,
        parentUri: parent.data.uri,
        position,
        positionLength,
        toggleOpen: getToggleFn(page.uri, isOpen),
        type: 'page',
      };

      // Set the selected page as our scroll target to initialize to.
      if (selectedPageSlug && page.slug === selectedPageSlug) {
        selectedNode = node;
      }

      return [
        // Add page to the tree.
        node,
        // Add child sub-pages directly after their parent page.
        ...getChildren(node),
        // Add slot for ephemeral page during new page creation.
        ...getEphemeralPage(node),
      ];
    }

    function getCategory(category: GitSidebarCategory, position: number, positionLength: number) {
      treeIndex += 1;
      /** Whether this is the special `API Config` category or not. */
      const isApiConfig = isApiConfigCategory(isReference ? 'reference' : null, category.title, category.pages);
      const isOpen = isOpenMap[category.uri] ?? true;
      const node: TreeNodeCategory = {
        data: category,
        height: defaultCategoryItemHeight,
        index: treeIndex,
        // Close all categories while dragging any category.
        isOpen: draggingCategory ? false : isOpen,
        position,
        positionLength,
        toggleOpen: getToggleFn(category.uri, isOpen),
        type: 'category',
      };
      return [
        // Add category to the tree.
        ...(isApiConfig ? [] : [node]),
        // Add slot before children for new ephemeral changelog.
        ...(isChangelog ? getEphemeralPage(node) : []),
        // Add child pages directly after their parent category.
        ...getChildren(node),
        // Add slot after children for new ephemeral page.
        ...(isChangelog ? [] : getEphemeralPage(node)),
        // Add dividers between each category except when dragging.
        ...(draggingCategory
          ? []
          : // Include divider line after our api_config category.
            getDivider(10, isApiConfig)),
      ];
    }

    return {
      /** Flat list of all tree nodes. */
      nodes: data.length
        ? [
            // Add a spacer at the top.
            ...getDivider(5),
            // Add API Definitions for reference section only.
            ...getApiDefinitions(),
            // Recursively add all categories and descendant pages.
            ...data.flatMap((category, index, categories) => {
              return getCategory(category, index, categories.length);
            }),
            // Add slot for ephemeral category during new category creation.
            ...getEphemeralCategory(),
            // Add a spacer at the bottom.
            ...getDivider(5),
          ]
        : [],

      /**
       * References a tree node that should be scrolled into view by the virtual
       * list that is rendering the tree.
       * @example
       * ```ts
       * const listRef = useRef(null);
       * const { nodes, scrollTarget } = useTreeNodes({ sidebarData });
       *
       * useEffect(() => {
       *   if (scrollTarget) {
       *     listRef.current?.scrollToItem(scrollTarget.index, 'auto');
       *   }
       * }, []);
       * ```
       */
      scrollTarget,

      /**
       * References the selected page node based on `selectedPageSlug`. Returns
       * undefined if no slug exists or page was found.
       */
      selectedNode,
    };
  }, [
    data,
    draggingCategory,
    draggingPage,
    ephemeralCategory,
    getEphemeralPageData,
    isChangelog,
    isOpenMap,
    isReference,
    selectedPageSlug,
    updateCollapsedStateById,
  ]);

  return treeNodes;
}
